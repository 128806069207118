.intro {
    text-align: center;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.dayone {
    text-align: left;
    justify-content: left;
    margin-left: 20px;
    margin-right: 30%;
}

.daytwo {
    text-align: right;
    justify-content: right;
    margin-left: 30%;
    margin-right: 20px;
}
