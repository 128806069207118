/* RSVP.module.css */
.container {
    font-family: 'Courier New', Courier, monospace;
    background-color: #F14A23;
    margin: 0;
    height: 100vh;
    display: block;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    color: #000000;
}

.title {
    text-align: top;
    margin-top: 100px;
    margin-bottom: 20px;
}

.paragraph {
    padding: 15px;
    margin: 20px auto;
    max-width: 600px;
}

.iframeContainer {
    position: relative;
    width: 100%;
    height: 100%;
    /* Full width of the parent container */
    padding-top: 56.25%;
    /* Aspect ratio: 16:9; for other ratios, adjust this value */
    margin-top: 20px;
}

.responsiveIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
