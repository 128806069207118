/* App.css */

body {
  font-family: 'Courier New', Courier, monospace;
  background-color: #F14A23;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000;
}

h1 {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
}

img {
  max-width: 100%;
  height: auto;
}
