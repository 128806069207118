/* Header.css */
header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;

}

.headerContainer {
    display: flex;
    justify-content: space-between;
    /* Aligns items to each end */
    align-items: center;
    /* Centers items vertically */
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* Ensures the header extends the full width */
}

nav {
    display: flex;
    /* Enables Flexbox for nav */
}

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    /* Enables Flexbox for ul */
    margin: 0;
}

nav ul li {
    display: inline;
    margin-right: 20px;
}

nav ul li a {
    text-decoration: none;
    color: black;
    /* Or any color you prefer */
    font-size: 22px;
    /* Adjust as needed */
}

nav ul li a.active {
    font-weight: bold;
    /* Makes the active link bold */
}

/* Header navigation and language switcher styles */
nav ul li a,
.languageSwitcher span {
    font-size: 18px;
    /* Default font size */
}

/* Adjust font size for smaller screens */
@media (max-width: 600px) {

    nav ul li a,
    .languageSwitcher span {
        font-size: 18px;
        /* Slightly smaller font size */
    }
}

@media (max-width: 400px) {

    nav ul li a,
    .languageSwitcher span {
        font-size: 16px;
        /* Even smaller font size */
    }
}
